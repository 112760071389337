export const myQuestionnaires = {
    action: "fa fa-question",
    title: "MyQuestionnaires",
    permission: "view-my-questionnaires",
    items: [
        {
            title: "Answered Questionnaires",
            prefix: "A",
            link: "/questionnaires/answeredQuestionnaires",
            permission: "",
        },
        {
            title: "Pending Questionnaires",
            prefix: "P",
            link: "/questionnaires/pendingQuestionnaires",
            permission: "",
        },
    ],
}

export const questionnaires = {
    action: "fa fa-question",
    title: "Questionnaires",
    permission: "view-questionnaires",
    items: [
        {
            title: "questionnairesQuestions",
            prefix: "Q",
            link: "/questionnaires/questions",
            permission: "view-questionnaire-question",
        },
        {
            title: "questionnairesGroups",
            prefix: "S",
            link: "/questionnaires/groups",
            permission: "view-questionnaire-group",
        },
        {
            title: "Questionnaires",
            prefix: "Q",
            link: "/questionnaires",
            permission: "view-questionnaires",
        },
        {
            title: "View Questionnaires",
            prefix: "Q",
            link: "/questionnaires/usersQuestionnaires",
            permission: "view-users-questionnaire",
        },
    ],
}