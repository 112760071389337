class report {
  get_create_data() {
    return axios.get('general/temporary_complaints/create')
  }
  create_complaints(form) {
    return axios.post('general/temporary_complaints/store', form)
  }
  get_all_complaints(page, data) {
    return axios.post('general/temporary_complaints?page=' + page, data)
  }
  // update_status(id, data) {
  //   return axios.post('general/temporary_complaints/reply/' + id, data)
  // }
  update_flow_status(data) {
    return axios.post('general/temporary_complaints/change-status', data)
  }
  get_complaint_by_id(id) {
    return axios.post('general/temporary_complaints/view/' + id)
  }


}

export default new report();