class SpecialistService {
  get_specialists(page, data) {
    return axios.post('general/specialist?page=' + page, data)
  }
  get_specialist(id) {
    return axios.get('general/specialist/edit/' + id)
  }
  add_specialist(data) {
    return axios.post('general/specialist/store', data)
  }
  edit_specialist(id, data) {
    return axios.post('general/specialist/update/' + id, data)
  }

}
export default new SpecialistService();