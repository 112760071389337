export const report = {
  action: "mdi-clipboard",
  title: "Complaints",
  permission: "complaints",
  items: [
    {
      title: "All Complaints",
      prefix: "CR",
      link: "/complaints",
      permission: "tmp-complaints-index",
    },
    {
      title: "Create Complaints",
      prefix: "CR",
      link: "/create-complaints",
      permission: "tmp-complaints-create",
    },
  ],
};
