export const violationForms = {
    action: "fa fa-hand-paper",
    title: "Violations",
    permission: "view-violations",
    items: [
        {
            title: "violationForms",
            prefix: "F",
            link: "/violations",
            permission: "view-violation-form",
        },
        {
            title: "violationAssignments",
            prefix: "A",
            link: "/violations/assignments",
            permission: "view-violations",
        },
        {
            title: "violationLevels",
            prefix: "L",
            link: "/violations/levels",
            permission: "view-violation-levels",
        },
    ],
}
