import clearanceService from '../services/clearance.service';
import Vue from "vue";
import i18n from '@/i18n.js'
import router from '@/router'
export const clearance = {
    namespaced: true,
    actions: {
        approved({ rootState }, item) {
            return clearanceService.store({ 'company_id': item.id, 'vote': 'accept' }).then(
                (response) => {
                    rootState.table.items.map(v => {
                        if (v.id == item.id) {
                            v.clearance_status = 'accept'
                            return v;
                        }
                    });
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    return Promise.reject(error);
                }
            );
        },



        action_reject({ rootState, commit }, data) {
            console.log(...data)
            data.append('company_id', rootState.id)
            data.append('vote', 'reject')
            return clearanceService.store(data).then(
                (response) => {
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    commit("form/SET_NOTIFY", {
                        msg: "تمت العملية بنجاح",
                        type: "Success"
                    }, { root: true });
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.clearance_status = 'reject'
                            return v;
                        }
                    });

                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.form.loader = false;
                    commit("form/SET_NOTIFY", {
                        msg: response.data.message,
                        type: "Success"
                    }, { root: true });
                    return Promise.reject(error);
                }
            );
        },


        // pagination section
        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page
            dispatch("getData", {})
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("getData", { pre_page, type: "pre_page" })
        },

        // filter section 
        filterData({ dispatch, rootState }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", {})
        },
        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", { reset: true })
        },

        getData({ rootState }, data) {
            // console.log(data)
            rootState.table.loading = true;
            const formData = new FormData();
            if (!data.hasOwnProperty('reset')) {

                rootState.form.filters.filter(function (filter) {
                    formData.append(filter.name, filter.value);
                });
            }
            if (data.hasOwnProperty('type') && data.type == "pre_page") {
                formData.append(
                    "paginate",
                    data.pre_page
                );
            } else {
                if (rootState.table.paginate.itemsPerPage != '')
                    formData.append(
                        "paginate",
                        rootState.table.paginate.itemsPerPage
                    );
            }

            return clearanceService.get_orders(rootState.table.paginate.page, formData)
                .then(
                    (response) => {
                        // alert(11)
                        console.log(response.data);
                        rootState.table.items = response.data.data.data
                        rootState.table.paginate = {
                            total: response.data.data.total,
                            itemsPerPage: parseInt(response.data.data.per_page),
                            row_pre_page: true,
                            page: rootState.table.paginate.page,
                        }
                        if (data.hasOwnProperty('reset') && data.reset) {

                            rootState.form.filters = response.data.filters;
                        }
                        rootState.table.loading = false;
                        return Promise.resolve(response);
                    },
                    (error) => {
                        // console.log(error);
                        rootState.table.loading = false;
                        if (error.response.status != 401) {
                            rootState.form.notify = {
                                msg: i18n.t("general.there is problem"),
                                type: "Danger",
                            };
                        }
                        return Promise.reject(error);
                    }
                )
        },

    },


};