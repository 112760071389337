export const ticket =
{
    action: "mdi-comment-processing",
    title: "Tickets",
    permission: "ticket",
    items: [
        {
            title: "Subject Tickets",
            prefix: "TS",
            link: "/ticket-subject",
            permission: "ticket-subject-index",
        },
        {
            title: "TicketPage",
            prefix: "SC",
            link: "/tickets",
            permission: "ticket-index",
        },
    ]
}