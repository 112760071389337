import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";
export default {
    path: "/publicBenefits",
    component: DashboardLayout,
    name: "publicBenefits",
    children: [
        {
            path: "/",
            name: "publicBenefits",
            component: () =>
                import("@/modules/public_benefits/views/PublicBenefits/index.vue"),
            meta: {
                groupName: "publicBenefits",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "view-public-benefits",
            },
        },
        {
            path: "new",
            name: "addPublicBenefit",
            component: () =>
                import("@/modules/public_benefits/views/PublicBenefits/new.vue"),
            meta: {
                groupName: "publicBenefits",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "add-public-benefits",
            },
        },

        {
            path: "edit/:id",
            name: "UpdatePublicBenefit",
            component: () =>
                import("@/modules/public_benefits/views/PublicBenefits/new.vue"),
            meta: {
                groupName: "publicBenefits",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "edit-public-benefits",
            },
        },

        {
            path: "assign/:id",
            name: "AssignPublicBenefit",
            component: () =>
                import("@/modules/public_benefits/views/PublicBenefits/assign.vue"),
            meta: {
                groupName: "publicBenefits",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "assign-public-benefits",
            },
        },


        {
            path: "camps",
            name: "PublicBenefitCamps",
            component: () =>
                import("@/modules/public_benefits/views/PublicBenefits/camps.vue"),
            meta: {
                groupName: "publicBenefits",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "view-public-benefits-assignments",
            },
        },


    ],


}