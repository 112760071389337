import TicketService from '../services/ticket.service'
import i18n from '@/i18n.js'
import router from '@/router'
export const ticket = {
    namespaced: true,

    mutations: {
        SET_SUBJECT_ID(state, data) {
            state.subject_id = data
        }
    },
    state: () => ({
        groups: [],
        admins: [],
        admin_data: [],


        // tickets
        subject_id: null,
        tickest_admin: [],
        tickest_customer: []
    }),
    actions: {
        // admin group
        getGroupsAdmin({ state, rootState }) {
            return TicketService.get_group_admin().then(
                res => {
                    // //console.log(res)
                    state.groups = res.data.groups
                    // for (let i = 0; i < state.groups.length; i++) {
                    //     const element = state.groups[i];
                    //     state.admin_data[element.id] = element.admins
                    // }
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },
        addGroupAdmin({ rootState, state }, data) {
            return TicketService.add_group_admin(data.data).then(
                res => {
                    // //console.log(res)
                    state.groups = state.groups.concat(res.data.group)
                    state.admin_data[res.data.group.id] = res.data.group.admins
                    state.admins = []
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            //console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        UpdateGroupAdmin({ rootState, state }, data) {
            return TicketService.edit_group_admin(data.id, data.data).then(
                res => {
                    // //console.log(res)
                    state.groups = state.groups.filter(v => v.id != data.id)
                    state.groups = state.groups.concat(res.data.group)
                    state.admin_data[res.data.group.id] = res.data.group.admins
                    state.admins = []
                    rootState.form.loader = false;
                    rootState.form.dialog = false;
                    rootState.form.notify = {
                        msg: res.data.message,
                        type: "Success",
                    };
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        //console.log(errors)
                        for (let i = 0; i < rootState.form.style_form.length; i++) {
                            const element = rootState.form.style_form[i];
                            //console.log(element)
                            element.error = errors[element.value_text];
                        }
                    } else {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            );
        },
        destroyGroupAdmin({ state }, id) {
            return TicketService.destroy_group_admin(id).then(
                res => {
                    // console.log(response)
                    state.groups = state.groups.filter(v => v.id != id)
                    state.admins = state.admins.splice(id, 1);
                    return Promise.resolve(res);
                },
                error => {
                    //console.log(error)
                    return Promise.reject(error);
                }
            );
        },
        destroyAdmin({ state }, data) {
            //console.log(data)
            return TicketService.destroy_admin_from_group(data).then(
                res => {
                    // //console.log(res)
                    state.admins = state.admins.filter(v => v.id != data.admin_id)
                    state.groups.forEach(function (v, index) {
                        // console.log(v)
                        // console.log(index)
                        if (v.id == data.ticket_admin_group_id) {
                            state.groups[index].admins = v.admins.filter(m => m.id != data.admin_id)
                        }
                    }
                    )
                    return Promise.resolve(res);
                },
                error => {
                    //console.log(error)
                    return Promise.reject(error);
                }
            );
        },
        getAdmins({ state }, id) {
            state.groups.forEach(function (v) {
                if (v.id == id) {
                    state.admins = v.admins
                }
            })
        },


        getTicketAdmin() {
            return TicketService.get_ticket_admin().then(
                res => {
                    // //console.log(res)
                    state.tickest_admin = res.data.tickest_admin
                    rootState.form.loader = false;
                    return Promise.resolve(res);
                },
                error => {
                    rootState.form.loader = false;
                    rootState.form.notify = {
                        msg: i18n.t("general.there is problem"),
                        type: "Danger",
                    };
                    return Promise.reject(error);
                }
            )
        },

        add_ticket({ rootState, state }, data) {
            data.append('subject_id', state.subject_id)
            return TicketService.add_ticket(data).then(
                res => {
                    // //console.log(res)
                    rootState.form.loader = false;
                    rootState.form.style_form = [];
                    router.push({
                        name: "TicketPage",
                        params: { message: { msg: res.data.message, type: "Success" } },
                    });
                },
                error => {
                    rootState.form.loader = false;
                    if (error.response.status == 422) {
                        var errors = error.response.data.errors;
                        for (
                            let i = 0;
                            i < rootState.form.style_form.length;
                            i++
                        ) {
                            const element = rootState.form.style_form[i];
                            element.error = errors[element.value_text];
                        }
                    } else if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: error.response.data.message,
                            type: "Danger",
                        };
                    }
                }
            );
        },


        // pagination section
        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page
            dispatch("getData", {})
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("getData", { pre_page, type: "pre_page" })
        },

        // filter section 
        filterData({ dispatch, rootState }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", {})
        },
        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", { reset: true })
        },

        getData({ rootState }, data) {
            // console.log(data)
            rootState.table.loading = true;
            const formData = new FormData();
            if (!data.hasOwnProperty('reset')) {

                rootState.form.filters.filter(function (filter) {
                    formData.append(filter.name, filter.value);
                });
            }
            if (data.hasOwnProperty('type') && data.type == "pre_page") {
                formData.append(
                    "paginate",
                    data.pre_page
                );
            } else {
                if (rootState.table.paginate.itemsPerPage != '')
                    formData.append(
                        "paginate",
                        rootState.table.paginate.itemsPerPage
                    );
            }
            // return OrderService.get_orders(rootState.table.paginate.page, formData).then(
            return axios.post(rootState.url + '?page=' + rootState.table.paginate.page, formData).then(
                (response) => {
                    // console.log(response.data);
                    rootState.table.items = response.data.data.data
                    rootState.table.paginate = {
                        total: response.data.data.total,
                        itemsPerPage: parseInt(response.data.data.per_page),
                        row_pre_page: true,
                        page: rootState.table.paginate.page,
                    }
                    if (data.hasOwnProperty('reset') && data.reset) {

                        rootState.form.filters = response.data.filters;
                    }
                    rootState.table.loading = false;
                    return Promise.resolve(response);
                },
                (error) => {
                    // console.log(error);
                    rootState.table.loading = false;
                    if (error.response.status != 401) {
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },
    },
};