import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";
export default {
    path: "/questionnaires",
    component: DashboardLayout,
    name: "questionnaires",
    children: [
        {
            path: "/",
            name: "Questionnaires",
            component: () =>
                import("@/modules/questionnaire/views/Questionnaires/index.vue"),
            meta: {
                groupName: "Questionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                // permissions: "order",
            },
        },
        {
            path: "new",
            name: "addNewQuestionnaire",
            component: () =>
                import("@/modules/questionnaire/views/Questionnaires/new.vue"),
            meta: {
                groupName: "Questionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "add-questionnaire",
            },
        },

        {
            path: "edit/:id",
            name: "UpdateQuestionnaire",
            component: () =>
                import("@/modules/questionnaire/views/Questionnaires/new.vue"),
            meta: {
                groupName: "Questionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "edit-questionnaire",
            },
        },

        /* my questionaires mean: questioners assigned to me */
        {
            path: "answeredQuestionnaires",
            name: "myAnsweredQuestionnaires",
            component: () =>
                import("@/modules/questionnaire/views/Questionnaires/myQuestionnaires.vue"),
            meta: {
                groupName: "MyQuestionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                title: 'myAnsweredQuestionnaires'
            },
        },
        {
            path: "pendingQuestionnaires",
            name: "myPendingQuestionnaires",
            component: () =>
                import("@/modules/questionnaire/views/Questionnaires/myQuestionnaires.vue"),
            meta: {
                groupName: "Questionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                title: 'myPendingQuestionnaires'
            },
        },


        {
            path: "myQuestionnaires/:id",
            name: "myQuestionnaire",
            component: () =>
                import("@/modules/questionnaire/views/Questionnaires/submitAnswer.vue"),
            meta: {
                groupName: "Questionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                // permissions: "",
            },
        },

        /* users Questionnaires mean: questioners assigned to any user(only admin can view this page) */

        {
            path: "usersQuestionnaires",
            name: "UsersQuestionnaires",
            component: () =>
                import("@/modules/questionnaire/views/Questionnaires/usersQuestionnaires.vue"),
            meta: {
                groupName: "Questionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "view-users-questionnaire",
            },
        },
        {
            path: "usersQuestionnaires/:id",
            name: "usersQuestionnaire",
            component: () =>
                import("@/modules/questionnaire/views/Questionnaires/submitAnswer.vue"),
            meta: {
                groupName: "Questionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "view-users-questionnaire",
            },
        },


        {
            path: "questions/new",
            name: "addNewQuestions",
            component: () =>
                import("@/modules/questionnaire/views/Questions/new.vue"),
            meta: {
                groupName: "Questionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "add-questionnaire-question",
            },
        },
        {
            path: "questions/:id",
            name: "updateQuestion",
            component: () =>
                import("@/modules/questionnaire/views/Questions/new.vue"),
            meta: {
                groupName: "Questionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "edit-questionnaire-question",
            },
        },
        {
            path: "questions",
            name: "questionnairesQuestions",
            component: () =>
                import("@/modules/questionnaire/views/Questions/index.vue"),
            meta: {
                groupName: "Questionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "view-questionnaire-question",
            },
        },


        {
            path: "groups/new",
            name: "addNewGroup",
            component: () =>
                import("@/modules/questionnaire/views/Groups/new.vue"),
            meta: {
                groupName: "Questionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "add-questionnaire-group",
            },
        },
        {
            path: "groups/:id",
            name: "updateGroups",
            component: () =>
                import("@/modules/questionnaire/views/Groups/new.vue"),
            meta: {
                groupName: "Questionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "edit-questionnaire-group",
            },
        },
        {
            path: "groups",
            name: "questionnairesGroups",
            component: () =>
                import("@/modules/questionnaire/views/Groups/index.vue"),
            meta: {
                groupName: "Questionnaires",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "view-questionnaire-group",
            },
        },



    ],


}