import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware/";
export default {

    path: "/",
    component: DashboardLayout,
    name: "Pledges",
    children: [{
        path: "/pledges/create",
        name: "NewPledge",
        component: () =>
            import("@/modules/orders/views/Pledges/New.vue"),
        meta: {
            groupName: "Pledge",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "pledge-create",
        },
    },
    {
        path: "/pledges/update/:id",
        name: "EditPledge",
        component: () =>
            import("@/modules/orders/views/Pledges/Edit.vue"),
        meta: {
            groupName: "Pledge",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "pledge-update",
        },
    },
    {
        path: "/pledges",
        name: "PledgePage",
        component: () =>
            import("@/modules/orders/views/Pledges/Show.vue"),
        meta: {
            groupName: "Pledge",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "order-pledge-index",
        },
    },

    ],


}