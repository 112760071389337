
class clearanceService {
  get_orders(page, post) {
    return axios.post('general/clearance?page=' + page, post)
  }

  store(data) {
    return axios.post('general/clearance/store', data)
  }
  view_user(id) {
    return axios.get('general/clearance/view/' + id)
  }



}
export default new clearanceService();