import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";
export default {
    path: "/",
    component: DashboardLayout,
    name: "Ticket",
    children: [
        {
            path: "ticket-subject",
            name: "TicketSubjectPage",
            component: () => import("@/modules/tickets/views/Subject/Show.vue"),
            meta: {
                groupName: "Tickets",
                permissions: 'ticket-subject-index',
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
            },
        },
        {
            path: "ticket-subject/create",
            name: "TicketSubjectCreatePage",
            component: () => import("@/modules/tickets/views/Subject/New.vue"),
            meta: {
                groupName: "Tickets",
                permissions: 'ticket-subject-create',
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
            },
        },
        {
            path: "ticket-subject/edit/:id",
            name: "TicketSubjectEditPage",
            component: () => import("@/modules/tickets/views/Subject/Edit.vue"),
            meta: {
                groupName: "Tickets",
                permissions: 'ticket-subject-update',
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
            },
        },

    ]
}