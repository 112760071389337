<template>
  <v-app>
    <div
      class="auth-page bg-page-login position-relative overflow-hidden"
      :style="
        $route.name == 'Signup'
          ? `background-image:  url(${require('../../assets/img/tsleem/bg-login.png')}`
          : `background-image:  url(${require('../../assets/img/tsleem/login-main.jpg')};`
      "
    >
      <div class="d-flex justify-space-between">
        <div class="text-start logo-content-right">
          <img
            src="@/assets/img/tsleem/rightlogo.png"
            class="navbar-brand-img position-relative"
            width="180"
          />
        </div>

        <div class="logo-content-left text-end px-5 px-md-0">
          <img
            src="@/assets/img/tsleem/logo-login.png"
            class="navbar-brand-img position-relative mt-5"
            width="210"
          />
        </div>
      </div>
      <v-container>
        <v-row>
          <!-- <v-col cols="12" v-if="$route.name == 'switch'">

          </v-col> -->
          <v-col
            :xl="colsXl"
            :lg="colsLg"
            :md="colsMd"
            :sm="colsSm"
            cols="12"
            class="d-flex flex-column mx-auto"
          >
            <!-- <div class="col-md-6 col-sm-8 col-lg-5 col-xl-4 col-xxl-3 mx-auto"> -->
            <fade-transition :duration="200" origin="center top" mode="out-in">
              <!-- your content here -->
              <router-view></router-view>
            </fade-transition>
          </v-col>
        </v-row>
      </v-container>
      <!-- end container fluid -->
    </div>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";
import ContentFooter from "@/components/Footer.vue";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    ContentFooter,
  },
  data() {
    return {};
  },
  computed: {
    colsXl() {
      if (this.$route.name == "Login") return 4;
      else if (this.$route.name == "switch") return 7;
      else return 5;
    },
    colsLg() {
      if (this.$route.name == "Login") return 5;
      else if (this.$route.name == "switch") return 8;
      else return 6;
    },
    colsMd() {
      if (this.$route.name == "Login") return 8;
      else if (this.$route.name == "switch") return 9;
      else return 7;
    },
    colsSm() {
      if (this.$route.name == "Login") return 10;
      else if (this.$route.name == "switch") return 10;
      else return 9;
    },
  },
};
</script>

