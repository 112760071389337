import AuthCoverLayout from "@/views/Layout/AuthCoverLayout";
import AuthBasicLayout from "@/views/Layout/AuthBasicLayout";
import Middleware from "@/middleware";

const LogIn = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/modules/auth/views/Login.vue"
    );
const ResetPassword = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/modules/auth/views/ResetPassword.vue"
    );
const Email = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/modules/auth/views/Email.vue"
    );

const Signup = () =>
    import(
        /* webpackChunkName: "Auth" */
        "@/modules/auth/views/Register/Register.vue"
    );

export const Login = {
    path: "/login",
    component: AuthCoverLayout,
    name: "Login-Page",
    children: [{
        path: "/login",
        name: "Login",
        component: LogIn,
        meta: {
            middlewares: [Middleware.gust],
        },
    },
    {
        path: "/reset_password",
        name: "Email",
        component: Email,
        meta: {
            middlewares: [Middleware.gust],
        },
    },
    {
        path: "/reset",
        name: "ResetPassword",
        component: ResetPassword,
        meta: {
            middlewares: [Middleware.gust],
        },
    },
    ],
};

export const SignUp = {
    path: "/register",
    component: AuthCoverLayout,
    name: "Signup-Page",
    children: [
        {
            path: "/register",
            name: "Signup",
            component: Signup,
            meta: {
                middlewares: [Middleware.gust],
            },
        },
    ],
};

export const RegisterFormPages = {
    path: "/",
    component: AuthBasicLayout,
    name: "RegisterFormPage",
    children: [{
        path: "/register-forms",
        name: "RegisterForm",
        component: () =>
            import("@/modules/auth/views/RegisterForm/RegisterFormIndex.vue"),
        meta: {
            groupName: "RegisterForm",
            middlewares: [Middleware.authReject],
        },
    },
    {
        path: "/complete-register-forms",
        name: "RegisterFormServiceProvider",
        component: () =>
            import("@/modules/auth/views/RegisterForm/RegisterFormServiceProvider.vue"),
        meta: {
            groupName: "RegisterForm",
            middlewares: [Middleware.authReject],
        },
    },],
};
