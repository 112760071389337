import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";
export default {
    path: "/",
    component: DashboardLayout,
    name: "Ticket",
    children: [

        {
            path: "tickets",
            name: "TicketPage",
            component: () => import("@/modules/tickets/views/Ticket/Show.vue"),
            meta: {
                groupName: "Tickets",
                permissions: 'ticket-index',
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
            },
        },
        {
            path: "tickets/create",
            name: "TicketCreatePage",
            component: () => import("@/modules/tickets/views/Ticket/New.vue"),
            meta: {
                groupName: "Tickets",
                permissions: 'ticket-create',
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
            },
        },
        {
            path: "tickets/details/:id",
            name: "DetailsTicket",
            component: () => import("@/modules/tickets/views/Ticket/Details.vue"),
            meta: {
                groupName: "Tickets",
                permissions: 'ticket-details',
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
            },
        },
    ]
}