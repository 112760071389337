const LicensePage = () => import("@/modules/orders/views/TmpLicense/Show.vue");
const NewLicense = () => import("@/modules/orders/views/TmpLicense/New.vue");
const EditLicense = () => import("@/modules/orders/views/TmpLicense/Edit.vue");

import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware/";
export default {

    path: "/",
    component: DashboardLayout,
    name: "TamplateLicense",
    children: [
        {
            path: "/licenses/create",
            name: "NewTmpLicense",
            component: NewLicense,
            meta: {
                groupName: "License",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "tmp-license-create",
            },
        },
        {
            path: "/licenses/update/:id",
            name: "EditTmpLicense",
            component: EditLicense,
            meta: {
                groupName: "License",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "tmp-license-update",
            },
        },
        {
            path: "/licenses",
            name: "TmpLicensePage",
            component: LicensePage,
            meta: {
                groupName: "License",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "order-tmp-license-index",
            },
        },
    ],
};