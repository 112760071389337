export default function guest({ nextMiddleware, router, store, contextNext }) {
  if (store.state.auth.loggedIn) {
    if (store.state.auth.user.arafat_status == "active") {
      return contextNext({
        path: "/dashboard",
      });
    }
    // else if (
    //   store.state.auth.user.arafat_status == "rejected" ||
    //   store.state.auth.user.arafat_status == "pending"
    // ) {
    //   return contextNext({
    //     path: "/register-forms",
    //   });
    // }
    // else if (
    //   store.state.auth.user.status == "active" && (store.state.auth.user.assign_file_approval == "pending" ||
    //     store.state.auth.user.assign_file_approval == "incorrect")
    // ) {
    //   return contextNext({
    //     path: "/complete-register-forms",
    //   });
    // }
  }
  return nextMiddleware();
}
