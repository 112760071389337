export const publicBenefits = {
    action: "fa fa-th-large",
    title: "publicBenefits",
    permission: "view-public-benefits",
    items: [
        {
            title: "publicBenefits",
            prefix: "P",
            link: "/publicBenefits",
            permission: "view-public-benefits",
        },
        {
            title: "publicBenefitsCamps",
            prefix: "C",
            link: "/publicBenefits/camps",
            permission: "view-public-benefits-assignments",
        },
    ],
}