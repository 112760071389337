<template>
  <div class="fixed-plugin" :class="showSettingsDrawer == true ? 'show' : ''">
    <v-card class="shadow-lg">
      <div class="card-padding">
        <div class="d-flex justify-space-between">
          <div></div>

          <div class="d-flex justify-center align-center">
            <h5 class="text-h4 text-typo font-weight-bold mb-0">
              {{ $t("Notification") }}
              <v-badge
                overlap
                color="bg-gray"
                :content="
                  searchText
                    ? filterNoti.length > 0
                      ? filterNoti
                      : '0'
                    : notificationList.length > 0
                    ? notificationList.length
                    : '0'
                "
              >
                <v-icon class="mr-2 ml-2" style="color: #3a416f"
                  >fa fa-bell text-lg</v-icon
                >
              </v-badge>
            </h5>
          </div>

          <div class="float-end">
            <v-btn
              :ripple="false"
              icon
              rounded
              width="20px"
              height="20px"
              class="text-dark"
              @click="$emit('toggleSettingsDrawer', false)"
            >
              <v-icon size="12">fa fa-times</v-icon>
            </v-btn>
          </div>
        </div>
      </div>

      <div>
        <v-text-field
          :placeholder="$t('Search') + '..'"
          variant="underlined"
          append-icon="fa fa-search"
          @keyup="search($event)"
          v-model="searchText"
          class="px-3"
        ></v-text-field>
        <v-list
          v-if="searchText"
          class="py-0"
          style="overflow: auto; height: 80vh"
        >
          <v-list-item
            v-for="(item, i) in filterNoti"
            :key="i"
            @click="see_notify(item)"
            :class="item.is_seen == 0 ? 'not_seen' : ''"
            class="pa-4 list-item-hover-active d-flex"
          >
            <v-list-item-content class="pa-0">
              <v-list-item-title
                class="text-body-2 ls-0 text-typo font-weight-600 mb-0 white-space-break"
              >
                <v-row>
                  <v-col>
                    <h6 class="text-md font-weight-normal mb-1 text-typo">
                      {{ item.message }}
                    </h6>
                  </v-col>
                </v-row>
              </v-list-item-title>

              <p class="text-xs text-secondary mb-0">
                <v-icon size="12" class="text-secondary"
                  >fa fa-clock me-1</v-icon
                >
                {{ item.created_at }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-else class="py-0" style="overflow: auto; height: 75vh">
          <v-list-item
            v-for="(item, i) in notificationList"
            :key="i"
            @click="see_notify(item)"
            :class="item.is_seen == 0 ? 'not_seen' : ''"
            class="pa-4 list-item-hover-active d-flex"
          >
            <v-list-item-content class="pa-0">
              <v-list-item-title
                class="text-body-2 ls-0 text-typo font-weight-600 mb-0 white-space-break"
              >
                <v-row>
                  <v-col>
                    <h6 class="text-md font-weight-normal mb-1 text-typo">
                      {{ item.message }}
                    </h6>
                  </v-col>
                </v-row>
              </v-list-item-title>

              <p class="text-xs text-secondary mb-0">
                <v-icon size="12" class="text-secondary"
                  >fa fa-clock me-1</v-icon
                >
                {{ item.created_at }}
              </p>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </div>
    </v-card>
  </div>
</template>
<script>
export default {
  props: {
    showSettingsDrawer: {
      type: Boolean,
      default: false,
    },
    notificationList: {
      type: Array,
      default: [],
    },
  },
  data() {
    return {
      searchText: "",
      filterNoti: [],
    };
  },
  methods: {
    see_notify(item) {
      this.$http
        .get("general/notification/make_notification_seen/" + item.id)
        .then((response) => {
          if (item.is_seen == 0) {
            item.is_seen = 1;
            this.increaseUnreadMessage();
          }
          window.location = item.link;
          // if (this.$route.path != item.link) this.$router.push(item.link);
        });
    },
    search(e) {
      this.filterNoti = this.notificationList.filter((noti) => {
        return noti.message.includes(e.target.value);
      });
    },
  },
};
</script>
