const create = () => import("@/modules/reports/views/create.vue");
const allComplaints = () => import("@/modules/reports/views/index.vue");
const view = () => import("@/modules/reports/views/view.vue");
import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";
export const ComplaintsPage = {
    path: "/",
    component: DashboardLayout,
    name: "Report",
    children: [
        {
            path: "/create-complaints",
            name: "report create",
            component: create,
            meta: {
                groupName: "Report",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "tmp-complaints-create",
            },
        },
        {
            path: "/complaints",
            name: "Complaints",
            component: allComplaints,
            meta: {
                groupName: "Report",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "tmp-complaints-index",
            },
        },
        {
            path: "/complaints/view/:id",
            name: "viewComplaints",
            component: view,
            meta: {
                groupName: "Report",
                middlewares: [
                    Middleware.auth,
                    Middleware.permission,
                    Middleware.signeture,
                ],
                permissions: "tmp-complaints-view",
            },
        },

    ]
};