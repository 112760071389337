import { saveRedirectionIntoStorage } from "@/util/helpers/LoginRedirectionHelpers/LoginRedirectionHelpers";

export default function authReject({ nextMiddleware, contextNext, store, to }) {
  if (!store.state.auth.loggedIn) {
    saveRedirectionIntoStorage(to.fullPath);
    return contextNext({
      path: "/login",
    });
  }
  // alert(store.state.auth.user.assign_file_approval)
  // if (store.state.auth.user.status == "active" && (store.state.auth.user.assign_file_approval != "pending" &&
  //   store.state.auth.user.assign_file_approval != "incorrect"
  // )) {
  //   alert(store.state.auth.user.assign_file_approval)
  //   return contextNext({
  //     path: "/403",
  //   });
  // } 
  // else 
  if (
    store.state.auth.user.arafat_status != "rejected" &&
    store.state.auth.user.arafat_status != "pending"
  ) {
    return contextNext({
      path: "/403",
    });
  }

  return nextMiddleware();
}
