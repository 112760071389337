class SquareService {
  get_stations(page, data) {
    return axios.post('gis/electrical_meters?page=' + page, data)
  }
  get_station(id) {
    return axios.get('gis/electrical_meters/edit/' + id)
  }
  add_station(data) {
    return axios.post('gis/electrical_meters/store', data)
  }
  edit_station(id, data) {
    return axios.post('gis/electrical_meters/update/' + id, data)
  }
  destroy_station(id) {
    return axios.delete('gis/electrical_meters/destroy/' + id)
  }
}
export default new SquareService();