class CountryService {
    get_countries(page, data) {
        return axios.post('general/countries?page=' + page, data)
    }
    get_country(id) {
        return axios.get('general/countries/edit/' + id)
    }
    add_country(data) {
        return axios.post('general/countries/store', data)
    }
    edit_country(id, data) {
        return axios.post('general/countries/update/' + id, data)
    }
    destroy_country(id) {
        return axios.delete('general/countries/delete/' + id)
    }
}
export default new CountryService();