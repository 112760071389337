import router from '@/router'
import i18n from '@/i18n.js'
import Vue from "vue";
import ReportService from "@/modules/reports/services/report.service";
import axios from 'axios';
export const complaints = {
    namespaced: true,
    state: () => ({

    }),

    actions: {

        update_flow_status({ commit, rootState }, item) {
            console.log(item);
            commit('form/SET_DIALOG', true, { root: true })
            rootState.id = item.id
            rootState.form.style_form[1].value = item.flow_status_id
            commit("SET_FUNCTION", "update_flow_status_complaints", { root: true });
        },

        update_flow_status_complaints({ commit, rootState }, data) {
            data.append('complaint_id', rootState.id)
            return ReportService.update_status(data).then(
                (response) => {
                    // console.log(response)
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.flow_status_text = response.data.data.flow_status_text
                            v.flow_status_id = response.data.data.flow_status_id
                            return v
                        }
                    });
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                },
                (error) => {
                    rootState.form.loader = false;
                    // console.log(error);
                    if (error.response.status == 422) {
                        rootState.form.style_form[0].error = errors.reject_reason;
                    } else if (error.response.status != 401) {
                        // commit('form/SET_DIALOG', false, { root: true });
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                }
            )
        },

        update_status({ commit, rootState }, item) {
            commit('form/SET_DIALOG', true, { root: true })
            rootState.id = item.id
            commit("SET_FUNCTION", "update_status_complaints", { root: true });
        },
        update_status_complaints({ commit, rootState }, data) {
            return ReportService.update_status(rootState.id, data).then(
                (response) => {
                    // console.log(response)
                    rootState.form.loader = false;
                    commit('form/SET_DIALOG', false, { root: true });
                    rootState.table.items.map(v => {
                        if (v.id == rootState.id) {
                            v.status = response.data.data.status
                            return v
                        }
                    });
                    rootState.form.notify = {
                        msg: response.data.message,
                        type: "Success",
                    };
                },
                (error) => {
                    rootState.form.loader = false;
                    // console.log(error);
                    if (error.response.status == 422) {
                        rootState.form.style_form[0].error = errors.reject_reason;
                    } else if (error.response.status != 401) {
                        // commit('form/SET_DIALOG', false, { root: true });
                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                }
            )
        },
        handlePageChange({ rootState, dispatch }, page) {
            rootState.table.paginate.page = page
            dispatch("getData", {})
        },

        changeItemPage({ rootState, dispatch }, pre_page) {
            rootState.table.paginate.itemsPerPage = parseInt(pre_page);
            rootState.table.paginate.page = 1;
            dispatch("getData", { pre_page, type: "pre_page" })
        },

        // filter section 
        filterData({ rootState, dispatch }) {
            console.log('filter')
            rootState.table.paginate.page = 1;
            dispatch("getData", {})
        },
        resetFilter({ rootState, dispatch }) {
            rootState.table.paginate.page = 1;
            dispatch("getData", { reset: true })
        },

        getData({ rootState }, data) {
            // console.log(data)
            rootState.table.loading = true;
            const formData = new FormData();
            if (!data.hasOwnProperty('reset')) {

                rootState.form.filters.filter(function (filter) {
                    formData.append(filter.name, filter.value);
                });
                console.log('reset1')
            }
            if (data.hasOwnProperty('type') && data.type == "pre_page") {
                formData.append(
                    "paginate",
                    data.pre_page
                );
            } else {
                if (rootState.table.paginate.itemsPerPage != '')
                    formData.append(
                        "paginate",
                        rootState.table.paginate.itemsPerPage
                    );
            }
            return ReportService.get_all_complaints(rootState.table.paginate.page, formData).then(
                (response) => {
                    console.log(response.data);
                    rootState.table.items = response.data.data.data
                    rootState.table.paginate = {
                        total: response.data.data.total,
                        itemsPerPage: parseInt(response.data.data.per_page),
                        row_pre_page: true,
                        page: rootState.table.paginate.page,
                    }
                    if (data.hasOwnProperty('reset') && data.reset) {
                        // console.log('reset2')
                        rootState.form.filters = response.data.filters;
                    }
                    rootState.table.loading = false;
                    return Promise.resolve(response);
                },
                (error) => {
                    console.log(error);
                    rootState.table.loading = false;
                    if (error.response.status != 401) {

                        rootState.form.notify = {
                            msg: i18n.t("general.there is problem"),
                            type: "Danger",
                        };
                    }
                    return Promise.reject(error);
                }
            )
        },

    },

};