import DashboardLayout from "@/views/Layout/DashboardLayout.vue";
import Middleware from "@/middleware";
export default {
    path: "/request-licenses",
    component: DashboardLayout,
    name: "RequestLicenses",
    children: [{
        path: "new",
        name: "Request New License",
        component: () =>
            import("@/modules/license/views/license/Request.vue"),
        meta: {
            groupName: "RequestLicenses",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "carLicense-request",
        },
    },
    {
        path: "/",
        name: "Licenses",
        component: () =>
            import("@/modules/license/views/license/index.vue"),
        meta: {
            groupName: "View licenses",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "carLicense-index",
        },
    },
    {
        path: "view/:id",
        name: "ViewRequestLicense",
        component: () =>
            import("@/modules/license/views/license/view.vue"),
        meta: {
            groupName: "RequestLicenses",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "carLicense-view",
        },
    },
    {
        path: "update/:id",
        name: "UpdateRequestLicense",
        component: () =>
            import("@/modules/license/views/license/update.vue"),
        meta: {
            groupName: "RequestLicenses",
            middlewares: [
                Middleware.auth,
                Middleware.permission,
                Middleware.signeture,
            ],
            permissions: "carLicense-update",
        },
    }
    ],


}