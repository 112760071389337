export default {
    action: "fa fa-handshake",
    title: "Licenses",
    permission: "carLicense-index",
    items: [
        {
            title: "View licenses",
            prefix: "R",
            link: "/request-licenses",
            permission: "carLicense-index",
        },
    ],
}