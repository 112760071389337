
import Vue from 'vue'
class BlackListService {
    get_companies_blacklist() {
        return axios.get('general/blacklists')
    }
    get_companies() {
        return axios.get('general/blacklists/desing-offices')
    }

    switch_company_in_blacklist(data) {
        return axios.post('general/blacklists/desing-office-blacklists', data, {
            baseURL: Vue.prototype.$baseMenaURL + 'api'
        })
    }
}
export default new BlackListService();